import React from 'react';
import { Link } from 'gatsby'
import Img from 'gatsby-image';

const ShopItem = ({ name, slug, price, fluid }) => {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2
  })
  return (
    <div className="articleItem">
      <Link to={`/shop/${slug}`}>
        <Img fluid={{ ...fluid, aspectRatio: 1 }} />
        <div className="meta">
          <h3 className="title">{name}</h3>
          <div className="price">
          {formatter.format(price)}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ShopItem;
