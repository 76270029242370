import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import ArticleItem from '../components/articleItem';
import Carousel from '../components/carousel';
import CarouselShop from '../components/carouselShop'
import { filterByFormat } from '../helpers/FilterByFormat'

const IndexPage = (props) => {
  const topThree = props.data.allWordpressPage.edges[0].node.acf.top_3_across;
  const products = props.data.allShopifyProduct;
  const hero = props.data.allWordpressPage.edges[0].node.acf.featured_post;
  const articles = filterByFormat(props.data.allWordpressPost.edges, 'standard', 2);
  const videos = filterByFormat(props.data.allWordpressPost.edges, 'video', 3);
  return (
    <Layout>
      <div className="page index">
        <div className="top-three row">
          {topThree.map(item => {
            return (
              <ArticleItem
                key={item.wordpress_id}
                slug={item.slug}
                title={item.title.rendered}
                fluid={item.featured_media.localFile ? item.featured_media.localFile.childImageSharp.fluid : ''}
                copy={item.excerpt.rendered}
                format={item.format}
                tags={item.tags}
                categories={item.categories}
              />
            )
          })}
        </div>
        <div>
          <h3 className="carousel-header">Featured</h3>
          <ArticleItem
            key={hero.id}
            slug={hero.slug}
            title={hero.title.rendered}
            fluid={hero.featured_media.localFile ? hero.featured_media.localFile.childImageSharp.fluid : ''}
            content={hero.content}
            format={hero.format}
            copy={hero.excerpt.rendered}
            tags={hero.tags}
            categories={hero.categories}
            customClass="hero"
          />
        </div>
        <Carousel title="Articles" link="articles" items={articles} />
        <Carousel title="Videos" link="videos" items={videos} />
        <CarouselShop title="shop" items={products} />
      </div>
    </Layout>
  )
}


export default IndexPage

export const query = graphql`
         query categoriesQuery {
           allWordpressPost(sort: { fields: [date], order: DESC }) {
             edges {
               node {
                 id
                 tags {
                   id
                   name
                   slug
                 }
                 categories {
                   id
                   name
                   slug
                 }
                 content
                 title
                 slug
                 excerpt
                 format
                 featured_media {
                   localFile {
                     childImageSharp {
                       fluid(maxWidth: 1600) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                 }
               }
             }
           }
           allWordpressPage(filter: { title: { eq: "Home" } }) {
             edges {
               node {
                 id
                 slug
                 title
                 date
                 status
                 acf {
                   featured_post {
                     wordpress_id
                     slug
                     format
                     excerpt {
                       rendered
                     }
                     title {
                       rendered
                     }
                     featured_media {
                       localFile {
                         childImageSharp {
                           fluid(maxWidth: 1600) {
                             ...GatsbyImageSharpFluid_withWebp
                           }
                         }
                       }
                     }
                   }
                   top_3_across {
                     wordpress_id
                     slug
                     format
                     excerpt {
                       rendered
                     }
                     title {
                       rendered
                     }
                     featured_media {
                       localFile {
                         childImageSharp {
                           fluid(maxWidth: 1600) {
                             ...GatsbyImageSharpFluid_withWebp
                           }
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
           allShopifyProduct {
             edges {
               node {
                 id
                 shopifyId
                 title
                 handle
                 descriptionHtml
                 productType
                 vendor
                 priceRange {
                   minVariantPrice {
                     amount
                   }
                   maxVariantPrice {
                     amount
                   }
                 }
                 images {
                   localFile {
                     childImageSharp {
                       fluid(maxWidth: 1600) {
                         ...GatsbyImageSharpFluid_tracedSVG
                       }
                     }
                   }
                 }
                 variants {
                   id
                   title
                   price
                 }
               }
             }
           }
         }
       `