import React from 'react';
import { Link } from 'gatsby'
import Slider from "react-slick";
import { connect } from 'react-redux'
import { ChevronLeft, ChevronRight } from 'react-feather';

import ShopItem from './shopItem';

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

class CarouselShop extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <SlickButtonFix><ChevronRight color="black" size={60} /></SlickButtonFix>,
      prevArrow: <SlickButtonFix><ChevronLeft color="black" size={60} /></SlickButtonFix>,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="shop">
        <Link
          to={`/${this.props.title}`}>
          <h1 className="carousel-header">{this.props.title}</h1>
        </Link>
        <Slider {...settings} className="carousel">
          {
            this.props.items.edges.map((item) => {
              return (
                <div className="slide" key={item.node.id}>
                  <ShopItem
                    name={item.node.title}
                    slug={item.node.handle}
                    fluid={item.node.images[0].localFile.childImageSharp.fluid}
                    inventory={item.node.variants}
                    price={item.node.variants[0].price}
                  />
                </div>
              )
            })
          }
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  product: state._product,
});

const connectedCarouselShop = connect(mapStateToProps, {})(CarouselShop);

export default connectedCarouselShop;
